<template>
    <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="PENDING_APPROVAL" tab="待审批">
            <table-page :config="config" />
        </a-tab-pane>
        <a-tab-pane key="REJECTED" tab="已拒绝">
            <table-page :config="configRejected" />
        </a-tab-pane>
        <a-tab-pane key="PASSED" tab="已通过">
            <table-page :config="configPassed" />
        </a-tab-pane>
        <a-tab-pane key="RETURNED" tab="已退回">
            <table-page :key="activeKey" :config="configReturned" />
        </a-tab-pane>
    </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
export default {
    name: 'SaasAdminRebateRulesReviewDetail',
    components: { TablePage },
    props: ['selectedRows'],
    data() {
        return {
            activeKey: 'PENDING_APPROVAL',
            config: {
                checkbox: true,
                dataUrl: `/approval/rebatesRules/list?auditStatusIs=PENDING_APPROVAL`,
                filter: {
                    initUrl: '/agency/institutionsCustomer/setAttributablePerson/get',
                    controls: [
                        {
                            key: 'name',
                            label: '机构名',
                            type: 'text',
                        },
                        {
                            key: 'orderNumber',
                            label: '订单号',
                            type: 'text'
                        },
                        {
                            key: 'activateId',
                            label: '提交人',
                            type: 'souSelect',
                            config: {
                                options: 'employeesList'
                            }
                        },
                    ]
                },
                columns: [
                    {
                        key: 'institutions.name',
                        title: '签单机构',
                        width: '130px',
                    },
                    {
                        key: 'orderRecord.signingTime',
                        title: '签单时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'orderRecord.orderNumber',
                        title: '订单号',
                        width: '130px',
                    },
                    {
                        key: 'institutionsCustomerRelationship.name',
                        title: '客户姓名',
                        width: '130px',
                    },
                    {
                        key: 'serviceItem.content',
                        title: '服务项目',
                        width: '100px',
                        align: 'center',
                    },
                    {
                        key: 'orderRecord.orderAmount',
                        title: '订单金额',
                        width: '150px',
                        type: 'numeric',
                        config: {
                            format: 'currency'
                        }
                    },
                    {
                        key: 'rebateType',
                        title: '返佣类型',
                        width: '100px',
                        type: 'enum',
                        config: {
                            constants: {
                                PROPORTIONAL_REBATES: {
                                    text: '比例返佣',
                                    color: 'orange'
                                },
                                FIXED_REBATES: {
                                    text: '固定返佣',
                                    color: 'green'
                                }
                            }
                        }
                    },
                    {
                        key: 'amountToBeRefunded',
                        title: '应返金额',
                        width: '150px',
                        type: 'numeric',
                        config: {
                            format: 'currency'
                        }
                    },
                    {
                        key: 'theAuthors.username',
                        title: '提交人',
                        width: '130px',
                    },
                    {
                        key: 'createdDate',
                        title: '提交时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'remarks',
                        title: '备注',
                        width: '200px',
                        ellipsis: true
                    },
                ],
                /* 表格操作 */
                actions: [
                    {
                        key: 'edit',
                        text: '通过',
                        icon: 'plus',
                        type: 'dialog',
                        isSelected: true,  // 是否必勾选
                        determine: true,  // 是否需要判定条
                        permission: '/approval/rebatesRules/pass',
                        modalObj: (rowData, tableData) => {
                            return `您共选择 ${rowData.length} 条返佣规则，是否全部通过? `
                        },
                        config: {
                            title: '审批通过',
                            submitUrl: '/approval/rebatesRules/pass',
                            submitText: '确认',
                            controls: []
                        }
                    },
                    {
                        key: 'edit',
                        text: '拒绝',
                        icon: 'plus',
                        type: 'dialog',
                        isSelected: true,  // 是否必勾选
                        determine: true,  // 是否需要判定条
                        permission: '/approval/rebatesRules/refuse',
                        modalObj: (rowData, tableData) => {
                            return `您共选择 ${rowData.length} 条返佣规则，是否全部拒绝? `
                        },
                        config: {
                            title: '审批拒绝',
                            submitUrl: '/approval/rebatesRules/refuse',
                            submitText: '确认',
                            controls: [
                                {
                                    key: 'reviewResponse',
                                    label: '审批回复',
                                    type: 'textarea',
                                    required: true,
                                    config: {
                                        rules: [
                                            { min: 1, max: 50, message: '只能输入1-50个字符' }
                                        ]
                                    }
                                },
                            ]
                        }
                    },
                ],
                /* 表格排序 */
                sorter: {
                    /* 可排序列 */
                    sortableColumns: ['createdDate'],
                    /* 默认排序 */
                    defaultSort: 'createdDate'
                },
            },
            configRejected: {
                dataUrl: `/approval/rebatesRules/list?auditStatusIs=REJECTED`,
                filter: {
                    initUrl: '/agency/institutionsCustomer/setAttributablePerson/get',
                    controls: [
                        {
                            key: 'name',
                            label: '机构名',
                            type: 'text',
                        },
                        {
                            key: 'orderNumber',
                            label: '订单号',
                            type: 'text'
                        },
                        {
                            key: 'activateId',
                            label: '提交人',
                            type: 'souSelect',
                            config: {
                                options: 'employeesList'
                            }
                        },
                    ]
                },
                columns: [
                    {
                        key: 'reviewResponse',
                        title: '审批回复',
                        width: '100px',
                        ellipsis: true
                    },
                    {
                        key: 'reviewer.username',
                        title: '审批人',
                        width: '100px',
                    },
                    {
                        key: 'reviewTime',
                        title: '审批时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'institutions.name',
                        title: '签单机构',
                        width: '100px',
                    },
                    {
                        key: 'orderRecord.signingTime',
                        title: '签单时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'orderRecord.orderNumber',
                        title: '订单号',
                        width: '130px',
                    },
                    {
                        key: 'institutionsCustomerRelationship.name',
                        title: '客户姓名',
                        width: '100px',
                    },
                    {
                        key: 'serviceItem.content',
                        title: '服务项目',
                        width: '80px',
                        align: 'center'
                    },
                    {
                        key: 'orderRecord.orderAmount',
                        title: '订单金额',
                        width: '200px',
                        type: 'numeric',
                        config: {
                            format: 'currency'
                        }
                    },
                    {
                        key: 'rebateType',
                        title: '返佣类型',
                        width: '100px',
                        type: 'enum',
                        config: {
                            constants: {
                                PROPORTIONAL_REBATES: {
                                    text: '比例返佣',
                                    color: 'orange'
                                },
                                FIXED_REBATES: {
                                    text: '固定返佣',
                                    color: 'green'
                                }
                            }
                        }
                    },
                    {
                        key: 'amountToBeRefunded',
                        title: '应返金额',
                        width: '200px',
                        type: 'numeric',
                        config: {
                            format: 'currency'
                        }
                    },
                    {
                        key: 'theAuthors.username',
                        title: '提交人',
                        width: '130px',
                    },
                    {
                        key: 'createdDate',
                        title: '提交时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'remarks',
                        title: '备注',
                        width: '200px',
                        ellipsis: true
                    },
                ],
                /* 表格操作 */
                actions: [],
                /* 表格排序 */
                sorter: {
                    /* 可排序列 */
                    sortableColumns: ['createdDate'],
                    /* 默认排序 */
                    defaultSort: '-createdDate'
                },
            },
            configPassed: {
                dataUrl: `/approval/rebatesRules/list?auditStatusIs=PASSED`,
                filter: {
                    initUrl: '/agency/institutionsCustomer/setAttributablePerson/get',
                    controls: [
                        {
                            key: 'name',
                            label: '机构名',
                            type: 'text',
                        },
                        {
                            key: 'orderNumber',
                            label: '订单号',
                            type: 'text'
                        },
                        {
                            key: 'activateId',
                            label: '提交人',
                            type: 'souSelect',
                            config: {
                                options: 'employeesList'
                            }
                        },
                    ]
                },
                columns: [
                    {
                        key: 'reviewer.username',
                        title: '审批人',
                        width: '100px',
                    },
                    {
                        key: 'reviewTime',
                        title: '审批时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'institutions.name',
                        title: '签单机构',
                        width: '100px',
                    },
                    {
                        key: 'orderRecord.signingTime',
                        title: '签单时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'orderRecord.orderNumber',
                        title: '订单号',
                        width: '130px',
                    },
                    {
                        key: 'institutionsCustomerRelationship.name',
                        title: '客户姓名',
                        width: '100px',
                    },
                    {
                        key: 'serviceItem.content',
                        title: '服务项目',
                        width: '80px',
                        align: 'center',
                    },
                    {
                        key: 'orderRecord.orderAmount',
                        title: '订单金额',
                        width: '200px',
                        type: 'numeric',
                        config: {
                            format: 'currency'
                        }
                    },
                    {
                        key: 'rebateType',
                        title: '返佣类型',
                        width: '100px',
                        type: 'enum',
                        config: {
                            constants: {
                                PROPORTIONAL_REBATES: {
                                    text: '比例返佣',
                                    color: 'orange'
                                },
                                FIXED_REBATES: {
                                    text: '固定返佣',
                                    color: 'green'
                                }
                            }
                        }
                    },
                    {
                        key: 'amountToBeRefunded',
                        title: '应返金额',
                        width: '200px',
                        type: 'numeric',
                        config: {
                            format: 'currency'
                        }
                    },
                    {
                        key: 'theAuthors.username',
                        title: '提交人',
                        width: '130px',
                    },
                    {
                        key: 'createdDate',
                        title: '提交时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'remarks',
                        title: '备注',
                        width: '200px',
                        ellipsis: true
                    },
                    {
                        key: 'name',
                        title: '操作',
                        fixed: 'right',
                        type: 'actions',
                        width: document.documentElement.clientWidth <= 768 ? '84px' : '150px'
                    }
                ],
                /* 表格操作 */
                actions: [],
                /* 表格排序 */
                sorter: {
                    /* 可排序列 */
                    sortableColumns: ['createdDate'],
                    /* 默认排序 */
                    defaultSort: '-createdDate'
                },
                rowActions: [
                    {
                        key: 'lock',
                        text: '退回',
                        type: 'confirm',
                        permission: '/approval/rebatesRules/returned',
                        disabled: ({ selectedRows }) => {
                            return true
                        },
                        config: {
                            color: 'primary',
                            submitColor: 'primary',
                            submitUrl: '/approval/rebatesRules/returned',
                            title: '退回后可以重新修改返佣规则重走审核流程，确定要退回吗',
                        }
                    },
                ]
            },
            configReturned: {
                dataUrl: `/approval/rebatesRules/list?auditStatusIs=RETURNED`,
                filter: {
                    initUrl: '/agency/institutionsCustomer/setAttributablePerson/get',
                    controls: [
                        {
                            key: 'name',
                            label: '机构名',
                            type: 'text',
                        },
                        {
                            key: 'orderNumber',
                            label: '订单号',
                            type: 'text'
                        },
                        {
                            key: 'activateId',
                            label: '提交人',
                            type: 'souSelect',
                            config: {
                                options: 'employeesList'
                            }
                        },
                    ]
                },
                columns: [
                    {
                        key: 'reviewer.username',
                        title: '审批人',
                        width: '100px',
                    },
                    {
                        key: 'reviewTime',
                        title: '审批时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'institutions.name',
                        title: '签单机构',
                        width: '100px',
                    },
                    {
                        key: 'orderRecord.signingTime',
                        title: '签单时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'orderRecord.orderNumber',
                        title: '订单号',
                        width: '130px',
                    },
                    {
                        key: 'institutionsCustomerRelationship.name',
                        title: '客户姓名',
                        width: '100px',
                    },
                    {
                        key: 'serviceItem.content',
                        title: '服务项目',
                        width: '80px',
                        align: 'center',
                    },
                    {
                        key: 'orderRecord.orderAmount',
                        title: '订单金额',
                        width: '200px',
                        type: 'numeric',
                        config: {
                            format: 'currency'
                        }
                    },
                    {
                        key: 'rebateType',
                        title: '返佣类型',
                        width: '100px',
                        type: 'enum',
                        config: {
                            constants: {
                                PROPORTIONAL_REBATES: {
                                    text: '比例返佣',
                                    color: 'orange'
                                },
                                FIXED_REBATES: {
                                    text: '固定返佣',
                                    color: 'green'
                                }
                            }
                        }
                    },
                    {
                        key: 'amountToBeRefunded',
                        title: '应返金额',
                        width: '200px',
                        type: 'numeric',
                        config: {
                            format: 'currency'
                        }
                    },
                    {
                        key: 'theAuthors.username',
                        title: '提交人',
                        width: '130px',
                    },
                    {
                        key: 'createdDate',
                        title: '提交时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'remarks',
                        title: '备注',
                        width: '200px',
                        ellipsis: true
                    }
                ],
                /* 表格操作 */
                actions: [],
                /* 表格排序 */
                sorter: {
                    /* 可排序列 */
                    sortableColumns: ['createdDate'],
                    /* 默认排序 */
                    defaultSort: '-createdDate'
                },
            }
        };
    }

};
</script>

<style lang="less" scoped></style>